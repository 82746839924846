// imports
import _get from 'lodash/get';
import _set from 'lodash/set';
import _find from 'lodash/find';

import Config from '$root/Config';
import JsonApiModel from '$dependencies/JsonApiModel';

import CategoryCollection from '$collections/CategoryCollection';
import PostcodeCollection from '$collections/PostcodeCollection';
import TeamCollection from '$collections/TeamCollection';
import PartnerApiCollection from '$collections/PartnerApiCollection';

import UrlModel from '$models/UrlModel';
import PhotoModel from '$models/PhotoModel';
import CommunityColorModel from '$models/CommunityColorModel';

// private static properties
const API_DICTIONARY = {
	uuid: 'id',
	field_community_f_bins_qr: 'bins_qr',
	field_community_categories: 'categories',
	field_community_post_codes: 'postcodes',
	field_community_teams: 'teams',
	field_community_f_bins: 'feature_bins',
	field_community_f_incidents: 'feature_incidents',
	field_community_p_url: 'url',
	field_community_p_logo: 'logo',
	field_community_color: 'theme_color',
	field_community_email: 'email',
	field_community_public_title: 'public_title',
	field_feature_volunteers: 'feature_volunteers',
	field_community_incident_api: 'incidents_api_active',
	field_community_api_partners: 'incidents_api_partners'
};

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	title: { type: String, default: '' },
	created: { type: Date, default: () => new Date() },
	changed: { type: Date, default: () => new Date() },
	categories: { type: Object, default: () => new CategoryCollection() },
	postcodes: { type: Object, default: () => new PostcodeCollection() },
	teams: { type: Object, default: () => new TeamCollection() },
	feature_bins: { type: Boolean, default: false },
	feature_incidents: { type: Boolean, default: false },
	feature_volunteers: { type: Boolean, default: false },
	url: { type: Object, default: () => new UrlModel() },
	logo: { type: Object, default: () => new PhotoModel(), referenceType: 'file--file' },
	theme_color: { type: Object, default: () => new CommunityColorModel() },
	email: { type: String, default: '' },
	public_title: { type: String, default: '' },
	bins_qr: { type: Boolean, default: false },
	incidents_api_active: {type: Boolean, default: false },
	incidents_api_partners: {type: Object, default: () => new PartnerApiCollection(), referenceType: 'taxonomy_term--api_partner' }
};

// class definition
export default class CommunityModel extends JsonApiModel {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.entityType = 'node--community';
		args.fields = FIELDS;

		// json api settings
		args.jsonApi = Object.assign(args.jsonApi || {}, {
			dictionary: API_DICTIONARY,
		});

		// call super constructor
		super(args);
	}

	// methods
	setConfig(prop, value) {
		// set config headers of children
		if (prop === 'headers') {
			if (this.categories) this.categories.setConfig(prop, value);
			if (this.postcodes) this.postcodes.setConfig(prop, value);
			if (this.teams) this.teams.setConfig(prop, value);
			if (this.logo) this.logo.setConfig(prop, value);
		}

		// super config
		return super.setConfig(prop, value);
	}

	fetch(data = {}) {
		// remove any parameters set by fetchByUid
		this.setConfig('url', `${Config.getUrl('community')}/${this.id}`);

		// run super fetch (make it an empty fetch when no data was passed)
		return super.fetch(data);
	}

	parse(data) {
		const logoId = _get(data, 'data.relationships.field_community_p_logo.data.id', '');
		const logo = new PhotoModel();
		const logoData = _find(_get(data, 'included', []), { id: logoId });

		_set(logoData, 'attributes.image_style_uri', _get(logoData, ['attributes', 'image_style_uri', 'community_logo'], ''));

		this.logo = logo.parse({ data: logoData });
		this.url = new UrlModel(_get(data, 'data.attributes.field_community_p_url', {}));
		this.color = new CommunityColorModel(_get(data, 'data.attributes.field_community_color', {}));

		return super.parse(data);
	}

	destroy() {}

	// utility methods

	// getters & setters
}
