import _isFunction from 'lodash/isFunction';
import _get from 'lodash/get';
import _upperFirst from 'lodash/upperFirst';

import Config from '../../Config';
import Logo from '..//Logo/Logo.vue';
import AdvancedSelect from '../../components/AdvancedSelect/AdvancedSelect.vue';
import CloseFormOverlay from '../../components/CloseFormOverlay/CloseFormOverlay.vue';
import DeleteOverlay from '../../components/DeleteOverlay/DeleteOverlay.vue';
import GdprOverlay from '../../components/GdprOverlay/GdprOverlay.vue';

const defaultValues = {
	title: '',
	showPrint: false,
	showInactive: false,
	showDuplicate: false,
	showDelete: false,
	exportTypes: false,
	showHelp: false,
	showLogo: false,
	showHamburger: true,
	isFakeOverlay: false,
	isHome: false,
	backUrl: false,
	type: null,
	dirty: false,
	modifiers: [],
	gdprExport: false,
	icon: {
		type: 'arrowClassicLeft',
		color: 'shakespeare',
	},
};

export default {
	props: {
		cfg: {
			type: Object,
			default: () => ({
				title: '',
			}),
			required: false,
		},

		logoModifiers: {
			type: Array,
			default: () => ['mooieStraat', 'landscape', 'coloredBg'],
		},
	},

	components: {
		Logo,
		AdvancedSelect,
		CloseFormOverlay,
		DeleteOverlay,
		GdprOverlay
	},

	mounted() {
		window.header = this;
	},

	data() {
		let cfg = { ...defaultValues, ...this.cfg };
		cfg = {
			...cfg,
			typeClass: cfg.type ? `mainHeader--${cfg.type}` : '',
			inactiveToggled: false,
			avSelectKey: 0,
			userDropdownActions: [
				{
					title: `${_upperFirst(cfg.itemType)} dupliceren`,
					icon: 'duplicate',
					onClick: () => {
						this.onDuplicateClick();
					},
					requiredPermissions: () => cfg.showDuplicate,
				},
				{
					title: 'Download als pdf',
					icon: 'download',
					onClick: () => this.onExportClick('pdf'),
					requiredPermissions: () => this.hasExporter && this.showExport('pdf'),
				},
				{
					title: 'Download inventaris (pdf)',
					icon: 'download',
					onClick: () => this.onExportClick('pdf'),
					requiredPermissions: () => this.hasExporter && this.showExport('pdf-inventory'),
				},
				{
					title: 'Download vergelijking (pdf)',
					icon: 'download',
					onClick: () => this.onExportBinComparisonClick(),
					requiredPermissions: () => this.hasExporter && this.showExport('comparison') && this.$store.state.binMeasurementComparison.parameters,
				},
				{
					title: 'Download projectinfo (pdf)',
					icon: 'download',
					onClick: () => this.onExportClick('pdf-projectinfo'),
					requiredPermissions: () => this.hasExporter && this.showExport('pdf-projectinfo'),
				},
				{
					title: 'Download meetformulier (pdf)',
					icon: 'download',
					onClick: () => this.onExportClick('countingForm'),
					requiredPermissions: () => this.hasExporter && this.showExport('countingForm')
				},
				{
					title: 'Download belevingsscans (pdf)',
					icon: 'download',
					onClick: () => this.onExportClick('pdf-experienceScans'),
					requiredPermissions: () => this.hasExporter && this.showExport('pdf-experienceScans')
				},
				{
					title: 'Download vergelijking (pdf)',
					icon: 'download',
					onClick: () => this.onExportClick('pdf-projectComparison'),
					requiredPermissions: () => this.hasExporter && this.showExport('pdf-projectComparison')
				},
				{
					title: 'Download groep (pdf)',
					icon: 'download',
					onClick: () => this.onExportClick('pdf-binGroup'),
					requiredPermissions: () => this.hasExporter && this.showExport('pdf-binGroup')
				},
				{
					title: 'Opruimacties afdrukken (pdf)',
					icon: 'print',
					onClick: () => this.onExportClick('pdf-events'),
					requiredPermissions: () => this.hasExporter && this.showExport('pdf-events')
				},
				{
					title: 'Mijn opruimacties afdrukken (pdf)',
					icon: 'print',
					onClick: () => this.onExportClick('pdf-events-user'),
					requiredPermissions: () => this.hasExporter && this.showExport('pdf-events-user')
				},
				{
					title: 'Groepen afdrukken (pdf)',
					icon: 'print',
					onClick: () => this.onExportClick('pdf-groups'),
					requiredPermissions: () => this.hasExporter && this.showExport('pdf-groups')
				},
				{
					title: 'Mijn groepen afdrukken (pdf)',
					icon: 'print',
					onClick: () => this.onExportClick('pdf-user-groups'),
					requiredPermissions: () => this.hasExporter && this.showExport('pdf-user-groups')
				},
				{
					title: 'Exporteer naar Excel',
					icon: 'export',
					onClick: () => this.onExportClick('excel'),
					requiredPermissions: () => this.hasExporter && this.showExport('excel'),
				},
				{
					title: 'Exporteer naar Excel',
					icon: 'export',
					onClick: () => this.onExportClick('xlsx-activities'),
					requiredPermissions: () => this.hasExporter && this.showExport('xlsx-activities'),
				},
				{
					title: 'Exporteer naar Excel',
					icon: 'export',
					onClick: () => this.onExportClick('xlsx-volunteers-active'),
					requiredPermissions: () => this.hasExporter && this.showExport('xlsx-volunteers-active'),
				},
				{
					title: 'Exporteer groepen naar Excel',
					icon: 'export',
					onClick: () => this.onExportClick('xlsx-groups'),
					requiredPermissions: () => this.hasExporter && this.showExport('xlsx-groups'),
				},
				{
					title: 'Exporteer mijn groepen naar Excel',
					icon: 'export',
					onClick: () => this.onExportClick('xlsx-user-groups'),
					requiredPermissions: () => this.hasExporter && this.showExport('xlsx-user-groups'),
				},
				{
					title: 'Exporteer leden naar Excel',
					icon: 'export',
					onClick: () => this.onExportClick('xlsx-group-members'),
					requiredPermissions: () => this.hasExporter && this.showExport('xlsx-group-members'),
				},
				{
					title: 'Exporteer opruimacties naar Excel',
					icon: 'export',
					onClick: () => this.onExportClick('xlsx-group-events'),
					requiredPermissions: () => this.hasExporter && this.showExport('xlsx-group-events'),
				},
				{
					title: 'Exporteer opruimacties naar Excel',
					icon: 'export',
					onClick: () => this.onExportClick('xlsx-events'),
					requiredPermissions: () => this.hasExporter && this.showExport('xlsx-events'),
				},
				{
					title: 'Exporteer mijn opruimacties naar Excel',
					icon: 'export',
					onClick: () => this.onExportClick('xlsx-user-events'),
					requiredPermissions: () => this.hasExporter && this.showExport('xlsx-user-events'),
				},
				{
					title: 'Exporteer deelnemers naar Excel',
					icon: 'export',
					onClick: () => this.onExportClick('xlsx-event-participants'),
					requiredPermissions: () => this.hasExporter && this.showExport('xlsx-event-participants'),
				},
				{
					title: 'Exporteer opruimsessies naar Excel',
					icon: 'export',
					onClick: () => this.onExportClick('xlsx-event-activities'),
					requiredPermissions: () => this.hasExporter && this.showExport('xlsx-event-activities'),
				},
				{
					title: 'Exporteer alle metingen (xls)',
					icon: 'export',
					onClick: () => this.onExportClick('xlsx-measurements-all'),
					requiredPermissions: () => this.hasExporter && this.showExport('xlsx-measurements-all'),
				},
				{
					title: 'Exporteer alle metingen (xls)',
					icon: 'export',
					onClick: () => this.onExportClick('xlsx-project-measurements-all'),
					requiredPermissions: () => this.hasExporter && this.showExport('xlsx-project-measurements-all'),
				},
				{
					title: 'Exporteer alle metingen (xls)',
					icon: 'export',
					modifiers: ['disabled'],
					requiredPermissions: () => this.hasExporter && this.showExport('excel-measurements-all-disabled'),
				},
				{
					title: 'Exporteer samenvatting (xls)',
					icon: 'export',
					onClick: () => this.onExportClick('xlsx-measurements-summary'),
					requiredPermissions: () => this.hasExporter && this.showExport('xlsx-measurements-summary'),
				},
				{
					title: 'Exporteer GeoJSON (GIS)',
					icon: 'export',
					onClick: () => this.onExportClick('geoJson'),
					requiredPermissions: () => this.hasExporter && this.showExport('geoJson'),
				},
				{
					title: 'Exporteer QR Codes (simpel)',
					icon: 'export',
					onClick: () => this.onExportClick('qrSimple'),
					requiredPermissions: () => this.hasExporter && this.showExport('qr') && this.$store.getters['session/community'].bins_qr,
				},
				{
					title: 'Exporteer QR Codes (uitgebreid)',
					icon: 'export',
					onClick: () => this.onExportClick('qr'),
					requiredPermissions: () => this.hasExporter && this.showExport('qr') && this.$store.getters['session/community'].bins_qr,
				},
				{
					title: 'Exporteer QR Code (simpel)',
					icon: 'export',
					onClick: () => this.onExportClick('qrSimple'),
					requiredPermissions: () => this.hasExporter && this.showExport('qrSingle') && this.$store.getters['session/community'].bins_qr,
				},
				{
					title: 'Exporteer QR Code (uitgebreid)',
					icon: 'export',
					onClick: () => this.onExportClick('qr'),
					requiredPermissions: () => this.hasExporter && this.showExport('qrSingle') && this.$store.getters['session/community'].bins_qr,
				},
				{
					title: this.showInActiveCopy,
					icon: 'eye',
					onClick: this.toggleActiveState,
					requiredPermissions: () => cfg.showInactive,
				},
				{
					title: `${_upperFirst(cfg.itemType)} verwijderen`,
					icon: 'bin',
					color: 'carnation',
					onClick: () => {
						this.$refs.deleteOverlay.show();
					},
					requiredPermissions: () => cfg.showDelete,
				},
				{
					title: 'Overzicht',
					onClick: this.toggleOverview,
					requiredPermissions: () => cfg.isIncidents,
				},
				{
					title: 'Overzicht met archief',
					onClick: this.toggleArchiveWithOverview,
					requiredPermissions: () => cfg.isIncidents,
				},
				{
					title: 'Archief',
					onClick: this.toggleArchive,
					requiredPermissions: () => cfg.isIncidents,
				},
			],
			gdprAction: null,
			faqLink: Config.faq.href,
		};

		return cfg;
	},

	computed: {
		isMobile() {
			return this.$store.state.isMobile;
		},
		isTablet() {
			return this.$store.state.isTablet;
		},

		gdprApproved() {
			return this.$store.state.volunteers.gdprApproved;
		},

		iconModifiers() {
			return `icon--${[this.icon.color, this.icon.type].join(' icon--')}`;
		},

		userProfileActions() {
			const loggedUser = (fieldName) => () => this.loggedUser[fieldName];
			const actions = [
				{
					title: loggedUser('full_name'),
					isDynamic: true,
					image: !this.isMobile ? '/images/icons/avatar-placeholder.svg' : '',
					icon: this.isMobile ? 'profileBurgermelding' : '',
					class: 'profile-icon',
					onClick: () => console.log('goToProfile'),
				},
				{
					title: 'Afmelden',
					icon: 'logout',
					onClick: () => this.onLogoutClick(),
				},
			];

			return actions;
		},

		modifierClasses() {
			return this.modifiers.map((m) => {
				m = `mainHeader--${m}`;
				return m;
			});
		},

		showInActiveCopy: {
			cache: false,
			get() {
				return this.inactiveToggled ? 'Enkel actieve tonen' : 'Niet actieve tonen';
			},
		},

		authenticated() {
			return this.$store.state.session.user.id;
		},

		hasExporter: {
			cache: false,
			get() {
				return !!this.$store.state.dataExporter;
			},
		},

		loggedUser() {
			return this.$store.state.session.user;
		},
	},

	methods: {
		onBackClick(e) {
			e.preventDefault();
			if (this.cfg.dirty) {
				this.$refs.dirtyOverlay.show();
			} else {
				this.onBack();
			}
		},

		onLogoutClick(e) {
			this.$store.dispatch('session/logOut');
			this.$router.push('/');
		},

		onDeleteClick() {
			if (_isFunction(this.onDelete)) {
				this.onDelete();
			}
		},

		onDuplicateClick() {
			if (_isFunction(this.onDuplicate)) {
				this.onDuplicate();
			}
		},

		onExportCountingFormClick() {
			if (_isFunction(this.onExportCountingForm)) {
				this.onExportCountingForm();
			}
		},

		onExportBinComparisonClick() {
			if (_isFunction(this.onExportBinComparison)) {
				const id = Date.now();
				this.$notify({
					id,
					group: 'mms-notifications',
					type: 'info',
					text: 'Export voorbereiden.',
					data: {
						showLoader: true,
					}
				})
				this.onExportBinComparison().then(() => {
					this.$notify.close(id)
					this.$notify({
						group: 'mms-notifications',
						type: 'success',
						text: 'Export klaar, download start zometeen.',
					})
				});
			}
		},

		onBack() {
			const previousEntry = this.$router.getPreviousEntry();
			const previousEntryBackUrl = _get(previousEntry, 'params.backUrl') || _get(previousEntry, 'query.backUrl');
			const routeBackUrl = this.$route.query.backUrl || this.$route.params.backUrl;
			const resolvedRoute = [routeBackUrl, previousEntryBackUrl, this.backUrl].find((route) => !!route);

			if (resolvedRoute) {
				const finalRoute = resolvedRoute === this.$route.fullPath ? this.backUrl : resolvedRoute;
				this.$router.push(finalRoute);
			} else {
				this.$router.goBack();
			}
		},

		toggleActiveState() {
			// filter options from av select option to change copy
			const toggle = this.$refs.userActions.options.filter((o) => o.title === this.showInActiveCopy)[0];
			// change toggled state to compute copy
			this.inactiveToggled = this.cfg.showInactiveFunction();
			// set new copy
			toggle.title = this.showInActiveCopy;
		},

		toggleOverview() {
			this.cfg.archiveFunction('overview');
		},

		toggleArchiveWithOverview() {
			this.cfg.archiveFunction('overviewwitharchive');
		},

		toggleArchive() {
			this.cfg.archiveFunction('archive');
		},

		onExportClick(type) {
			if (this.$store.state.dataExporter) {
				if(this.cfg.gdprExport) {
					if(!this.gdprApproved) {
						this.$refs.gdprOverlay.show();
						this.gdprAction = () => this.exportData(type);
						return;
					} else {
						this.exportData(type);
					}
				} else {
					this.exportData(type);
				}
			}
		},

		exportData(type) {
			switch (type) {
				case 'xlsx-activities':
				case 'xlsx-volunteers-active':
				case 'xlsx-groups':
				case 'xlsx-user-groups':
				case 'xlsx-group-members':
				case 'xlsx-group-events':
				case 'xlsx-events':
				case 'xlsx-user-events':
				case 'xlsx-event-participants':
				case 'xlsx-event-activities':
				case 'xlsx-measurements-all':
				case 'xlsx-measurements-summary':
				case 'xlsx-project-measurements-all':
					this.$notify({
						id: 'xlsxPusher',
						group: 'mms-notifications',
						type: 'info',
						text: 'Export voorbereiden.',
						data: {
							showLoader: true,
						}
					})

					this.$store.state
						.dataExporter(type)
						.then(() => {
							this.$notify.close('xlsxPusher')
							this.$notify({
							group: 'mms-notifications',
								type: 'success',
								text: 'Export klaar, download start zometeen.',
							})
						})
						.catch((e) => {
							this.$notify.close('xlsxPusher')
							this.$notify({
								group: 'mms-notifications',
								type: 'error',
								text: 'Het exporteren van de data is mislukt, probeer later nog eens.',
							})
						});
					break
				default:
					var id = Date.now()
					this.$notify({
						id,
						group: 'mms-notifications',
						type: 'info',
						text: 'Export voorbereiden.',
						data: {
							showLoader: true,
						}
					})

					this.$store.state
						.dataExporter(type)
						.then(() => {
							this.$notify.close(id)
							this.$notify({
							group: 'mms-notifications',
								type: 'success',
								text: 'Export klaar, download start zometeen.',
							})
						})
						.catch((e) => {
							console.log(e);
							this.$notify.close(id)
							this.$notify({
								group: 'mms-notifications',
								type: 'error',
								text: 'Het exporteren van de data is mislukt, probeer later nog eens.',
							})
						});
					break
			}
		},

		onGdprClose() {
			if(this.gdprAction) {
				this.gdprAction();
			}
		},

		showExport(type) {
			return this.exportTypes ? this.exportTypes.indexOf(type) > -1 : false;
		},
	},

	watch: {},
};
