// imports
import _forEach from 'lodash/forEach';
import _omit from 'lodash/omit';
import Config from '$root/Config';

import PermissionCollection from '$collections/PermissionCollection';
import ActiveVolunteersCollection from '$collections/volunteers/ActiveVolunteersCollection';
import ActivityCollection from '$collections/volunteers/ActivityCollection';
import ActivityMarkerCollections from '../../collections/volunteers/ActivityMarkerCollection';
import ActivityHeatMapCollection from '../../collections/volunteers/AcitvityHeatMapCollection';
import TaxonomyCollection from '../../collections/TaxonomyCollection';
import FilterOptionCollection from '../../collections/FilterOptionCollection';

export default {
	namespaced: true,
	state() {
		return {
			gdprApproved: false,
			permissions: new PermissionCollection({
				config: { url: Config.getUrl('permissions') },
			}),
			volunteers: new ActiveVolunteersCollection({
				config: { url: Config.getUrl('volunteers')}
			}),
			activities: new ActivityCollection({
					url: Config.getUrl('activities'),
			}),
			activityMarkers: new ActivityMarkerCollections({
				config: { url: Config.getUrl('activityMarkers')}
			}),
			activityHeatmapData: new ActivityHeatMapCollection({
				config: { url: Config.getUrl('activityHeatMapData')}
			}),
			c_events: new FilterOptionCollection({
				type: 'c_events',
				config: {
					params: {
						format: 'simple'
					}
				}
			}),
			c_groupTypes: new TaxonomyCollection({
				type: 'c_groupTypes',
			}),
			group_filters: new FilterOptionCollection({
				type: 'c_groups',
				config: {
					params: {
						format: 'simple'
					}
				}
			}),
		};
	},

	mutations: {
		UPDATE_AUTH(state, payload) {
			const authorizationToken = { Authorization: payload.jwt_authorization };
			const stateValuesToUpdateJWT = _omit(state, [
				'gdprApproved',
			]);

			// update jwt
			_forEach(stateValuesToUpdateJWT, (item) => {
				item.setConfig('headers', authorizationToken);
			});
		},

		ON_LOGOUT(state) {
			_forEach(state, (item) => {
				if(item.unsetConfig) {
					item.unsetConfig('headers').clear();
				}
			});
		},

		updateGdpr(state, payload) {
			state.gdprApproved = payload;
		}
	},
};
