export function getOriginalErrorFromMCEvent(e) {
	if (e.data) {
		if (e.data.error) {
			if (e.data.error.originalEvent) {
				return getOriginalErrorFromMCEvent(e.data.error.originalEvent);
			}
			return e.data.error.response;
		}
	}
	return e;
}

export function checkDeepProp(obj, search) {
	const parts = search.split('.');
	let current = obj;

	for (const part of parts) {
		if (current[part] === undefined) return false;
		current = current[part];
	}

	return true;
}

export function deepCloneObj(obj) {
	const newObj = {};
	if(obj){
		for (const p of Object.keys(obj)) {
			if (Array.isArray(obj[p])) {
				const arr = [];
				for (let i = 0; i < obj[p].length; i++) {
					const item = obj[p][i];
					arr.push(typeof item === 'object' ? deepCloneObj(item) : item);
				}
				newObj[p] = arr;
			} else {
				newObj[p] = typeof obj[p] === 'object' ? deepCloneObj(obj[p]) : obj[p];
			}
		}
	}
	return newObj;
}
